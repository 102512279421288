<template>
  <v-app>
    <div v-if="dataJson">
      <div class="row">
        <div class="col-xl-12">
          <v-card
            class="mx-auto rounded-lg ecosistema-aut"
            elevation="10"
            height="100%"
            outlined
          >
            <v-card-title>
              <v-avatar size="56">
                <img alt="Autoconsumo" src="/media/cide/ecosistemas/AUT.png" />
              </v-avatar>
              <h1 class="ml-3 texto-ecosistema-aut">
                Despliegue del autoconsumo en el medio rural
              </h1>
            </v-card-title>
            <v-card-text>
              <div class="row">
                <div class="col-xl-8" />
                <div class="col-xl-2">Hogares rurales con autoconsumo</div>
                <div class="col-xl-2">
                  Empresas de zonas rurales con autoconsumo
                </div>
              </div>

              <div class="row">
                <div class="col-xl-8">
                  <span class="font-weight-medium titulo"
                    >Porcentaje de instalaciones de autoconsumo instaladas en
                    zonas rurales 2021
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[0].data.hogar }} %
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[0].data.empresa }} %
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8">
                  <span class="font-weight-medium titulo"
                    >Porcentaje de instalaciones de autoconsumo instaladas en
                    zonas rurales 2022
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[1].data.hogar }} %
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[1].data.empresa }} %
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8">
                  <span class="font-weight-medium titulo"
                    >Porcentaje de instalaciones de autoconsumo instaladas en
                    zonas rurales 2023
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2].data.hogar }} %
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2].data.empresa }} %
                  </span>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-6">
          <v-card class="mx-auto rounded-lg" elevation="10" outlined>
            <v-card-title>
              Porcentaje de usuarios rurales con autoconsumo
            </v-card-title>
            <v-card-subtitle>
              % de hogares rurales con autoconsumo
            </v-card-subtitle>
            <v-card-text>
              <fusioncharts
                type="spain"
                width="100%"
                height="500"
                data-format="jsonurl"
                :data-source="'/data/AUT/nacional/mapa.json'"
              />
            </v-card-text>
          </v-card>
        </div>
        <div class="col-xl-6">
          <v-card class="mx-auto rounded-lg" elevation="10" outlined>
            <v-card-title>
              Porcentaje de usuarios rurales con cada tipo de autoconsumo:
              colectivo o individual
            </v-card-title>
            <v-card-subtitle>
              % de población rural por tipo de autoconsumo (Individual o
              colectivo)
            </v-card-subtitle>
            <v-card-text>
              <fusioncharts
                type="msstackedcolumn2d"
                width="100%"
                height="500"
                data-format="jsonurl"
                :data-source="'/data/AUT/nacional/inp03.json'"
              />
            </v-card-text>
          </v-card>
        </div>
      </div>

      <v-row>
        <v-col cols="12" lg="6">
          <v-card class="mx-auto rounded-lg" elevation="10" outlined>
            <v-card-title>
              Porcentaje de usuarios rurales con intención de instalar/ampliar
              autoconsumos
            </v-card-title>
            <v-card-subtitle>% de población rural </v-card-subtitle>
            <v-card-text>
              <fusioncharts
                type="msstackedcolumn2d"
                width="100%"
                height="400"
                data-format="jsonurl"
                :data-source="'/data/AUT/nacional/inp04.json'"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="6">
          <v-card class="mx-auto rounded-lg" elevation="10" outlined>
            <v-card-title>
              ¿Porcentaje de usuarios rurales que conocen el concepto de
              comunidades energéticas?
            </v-card-title>
            <v-card-subtitle>% de población rural </v-card-subtitle>
            <v-card-text>
              <fusioncharts
                type="msstackedcolumn2d"
                width="100%"
                height="400"
                data-format="jsonurl"
                :data-source="'/data/AUT/nacional/inp05.json'"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "Dashboard",
  components: {},
  data() {
    return {
      selectedVariable:
        "Contribución a la descarbonización del Vehículo Eléctrico",
      selectedYear: "2020",
      dataJson: null,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    this.getJsonData();
  },
  methods: {
    async getJsonData() {
      await fetch("/data/AUT/nacional/data.json")
        .then((response) => response.json())
        .then((data) => {
          this.dataJson = data;
        });
    },
  },
};
</script>
<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>
